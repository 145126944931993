<template>
    <section id="header">

        <div class="heading">
            <Button v-show="buttonLabel !== 'none'" btnStyle="btn-secondary" :label="buttonLabel" :isElsiCard="isElsiCard"
                :chevron="true" :chevronLeft="true" :link="link" :linkTo="linkTo"
                @back-clicked="$emit('back-clicked')"></Button>
            <div v-if="tutorialName === 'none'" style="position: absolute; right: 50px">

                <b-list-group horizontal>
                    <b-list-group-item v-for="(item, index) in this.iconsNoTutorial" :key="index"
                        @click="click($event, item)" :id="`nav-header-${item}`" class="icon-item noborder clickable"
                        :style="{ backgroundColor: item === 'question-circle' ? '#F15F62' : '#ECF0F3', color: item === 'question-circle' ? 'white' : 'black' }">
                        <div v-if="item === 'question-circle'">
                            <feather-icon class="clickable aufgabe" font-scale="1.5" icon="HelpIcon"></feather-icon>
                        </div>
                        <div v-if="item === 'trash-fill'">
                            <feather-icon class="clickable aufgabe" font-scale="1.5" icon="Trash2Icon"></feather-icon>
                        </div>
                        <div v-if="item === 'download'">
                            <feather-icon class="clickable" font-scale="1.5" icon="DownloadIcon"></feather-icon>
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </div>

            <div v-else style="position: absolute; right: 50px">
                <b-list-group horizontal>
                    <b-list-group-item v-for="(item, index) in this.icons" :key="index" @click="click($event, item)"
                        :id="`nav-header-${item}`" class="icon-item noborder clickable"
                        :style="{ backgroundColor: item === 'question-circle' ? '#F15F62' : '#ECF0F3', color: item === 'question-circle' ? 'white' : 'black' }">
                        <div v-if="item === 'question-circle'">
                            <feather-icon class="clickable" font-scale="1.5" icon="HelpCircleIcon"></feather-icon>
                        </div>
                        <div v-if="item === 'trash-fill'">
                            <feather-icon class="clickable aufgabe" font-scale="1.5" icon="Trash2Icon"></feather-icon>
                        </div>
                        <div v-if="item === 'download'">
                            <feather-icon class="clickable" font-scale="1.5" icon="DownloadIcon"></feather-icon>
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </div>

        </div>

        <b-tooltip target="nav-header-question-circle" title="Tutorial anzeigen"></b-tooltip>
        <b-tooltip target="nav-header-download" title="Projekt herunterladen"></b-tooltip>
        <b-tooltip target="nav-header-trash-fill" title="Projekt löschen"></b-tooltip>

        <b-modal v-model="showTutorial" title='Hinweise' ok-only>
            <Tutorial :tutorialName="tutorialName" />

            <template #modal-footer>
                <div v-if="tutorialName === 'valueReflection'">
                    <p>Das Resultat dieser Übung hat keinen Einfluss auf die weiteren Ergebnisse im ELSI-Profil oder der
                        Projektreflexion.</p>
                </div>

                <div v-if="tutorialName === 'survey'">
                    <p>Wir empfehlen, bei Unsicherheit eher die intuitiv angemessene Antwortmöglichkeit anzukreuzen als auf
                        „Noch unklar” auszuweichen, da letzteres die Einordnung weniger eindeutig macht.</p>
                </div>

                <div v-if="tutorialName === 'profile'">
                    <p>Jeder Kategorie sind unterschiedlich viele Fragen zugeordnet. Der Relevanzindikator füllt sich
                        relativ zu dieser Anzahl an Fragen. Daher sind z.B. die Segmente bei „persönliche Integrität“ kürzer
                        als diejenigen bei „Umwelt“.</p>
                </div>

                <div v-if="tutorialName === 'projectReflection'">
                    <!-- hides buttons in footer -->
                    <span></span>
                </div>
            </template>
        </b-modal>

        <b-modal size="lg" v-model="showDownload" title="Projekt herunterladen" :hide-footer=true>
            <Download />
        </b-modal>

        <b-modal v-model="showModalDelete" title="Projekt löschen" ok-title="Löschen" cancel-title="Abbrechen"
            @ok="handleOk" footer-class="modal-footer-buttons">
            <DeleteProjectModal />
        </b-modal>

    </section>
</template>

<script>

import Button from '../components/Button.vue';
import Tutorial from '../views/Tutorial.vue';
import Download from '../components/Modal_Project_Download.vue';
import html2pdf from 'html2pdf.js';
import FeatherIcon from '../components/FeatherIcon.vue';
import DeleteProjectModal from "../components/Modal_Delete_Project";

export default {
    name: 'nav-header',
    props: {
        buttonLabel: String,
        buttonFunction: String,
        link: Boolean,
        linkTo: String,
        tutorialName: String,
        isElsiCard: Boolean
    },
    components: {
        Button,
        Tutorial,
        Download,
        FeatherIcon,
        DeleteProjectModal
    },
    computed: {
        getPathName() {
            return this.$route.path
        },
        getLastName() {
            return this.$route.params.previousRoute
        },
    },
    methods: {
        downloadOK() {
            switch (this.downloadOption) {
                case 'json':
                    this.saveFile()
                    break;
                case 'pdf':
                    this.createPDF()
                    break;
            }
        },
        getOption(option) {
            this.downloadOption = option

        },
        backClicked() {
            this.$router.back()
        },
        click(evt, item) {
            switch (item) {
                case 'question-circle':
                    //this.$emit('showTutorial-clicked')
                    this.showTutorial = !this.showTutorial
                    break;
                case 'download':
                    this.showDownload = !this.showDownload
                    //this.saveFile()
                    break;
                case 'trash-fill':
                    this.showModalDelete = !this.showModalDelete
                    break;
            }
        },
        createPDF() {
            let opt = {
                margin: 30,
                filename: 'ELSI-SAT Ergebnis.pdf',
                image: { type: 'jpeg', quality: 1 },
                // Scales larger than 2 tend to result in empty pages.
                // https://github.com/eKoopmans/html2pdf.js/issues/19#issuecomment-315583260
                html2canvas: { scale: 1.8 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                pagebreak: { mode: 'avoid-all' }
            };

            const element = document.getElementById('pdf-export-content');

            html2pdf().from(element).set(opt).toPdf().get('pdf').then(function (pdf) {
                let totalPages = pdf.internal.getNumberOfPages();

                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.setTextColor(150);
                    pdf.text(
                        'Seite ' + i + ' von ' + totalPages,
                        pdf.internal.pageSize.getWidth() - 116,
                        pdf.internal.pageSize.getHeight() - 15
                    );
                }
            }).save();

        },
        saveFile: function () {

            const data = JSON.stringify(this.$store.state.survey)
            const blob = new Blob([data], { type: 'text/plain' })
            const e = document.createEvent('MouseEvents'),
                a = document.createElement('a');
            a.download = "ELSI-SAT-Export.json";
            a.href = window.URL.createObjectURL(blob);
            a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
            e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            a.dispatchEvent(e);
        },
        deleteProject() {
            this.showModalDelete = false
            this.deleteFinal = false
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.$store.commit('survey/DELETE_PROJECT')
            this.$emit('deleteProject')
            this.showModalDelete = false
            this.$router.push('/');
            //this.deleteFinal = !this.deleteFinal
        },
    },
    data() {
        return {
            showTutorial: false,
            icons: ['question-circle', 'trash-fill', 'download'],
            iconsNoTutorial: ['trash-fill', 'download' ],
            showDownload: false,
            downloadOption: '',
            showModalDelete: false,
            deleteFinal: false,
        }
    }
}

</script>

<style lang="scss" scoped>
.aufgabe {
    color: $elsi-color-aufgabe;
}

section#header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 32px 32px 0px 0px;
    gap: 20px;
    width: 100%;
    height: 100px;

    .heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

}

.icon-item {
    background-color: #ECF0F3;
    margin-left: 1rem;
    border-radius: 3px;

    &:hover {
        background-color: white;
    }

}
</style>