<template>
    <div id="pdf-export-root">
        <div id="pdf-export-content">

            <div>
                <h1>ELSI-SAT H&C</h1>
                <h5>Projektübersicht {{ projectMetadata.nameProject }} </h5>

                <div style="display: flex">
                    <div v-if="projectMetadata.wantedResult !== ''">
                        <b>{{ projectMetadata.wantedResult }} </b>
                    </div>
                    <div v-if="projectMetadata.field !== ''">
                        <b> | {{ projectMetadata.field }} </b>
                    </div>
                    <div v-if="projectMetadata.targetGroup !== ''">
                        <b> | {{ projectMetadata.targetGroup }} </b>
                    </div>
                </div>
                <div v-if="projectMetadata.projectDescription !== ''">
                    {{ projectMetadata.projectDescription }}
                </div>
            </div>

            <div v-if="options.includes('wertesystem')" style="page-break-after: always;">
                <div>
                    <b-card class="overflow-auto" body-class="text-center"
                        style="width: 100%; height: 100%; margin-top: 2rem; border: none" footer-class="align-self-center"
                        footer-tag="footer" footer-border-variant="none" footer-bg-variant="white">
                        <ValueReflectionPDF />
                        <div class="html2pdf__page-break"></div>
                    </b-card>
                </div>
            </div>

            <div v-if="options.includes('profil')" style="page-break-after: always;">
                <ProfilePDF />
            </div>

            <div v-if="options.includes('reflexion')" style="page-break-after: always;">
                <ProjectReflectionPDF />
            </div>

        </div>
    </div>
</template>

<script>

import html2pdf from 'html2pdf.js';

export default {
    name: "pdf-template",
    props: {
        options: Array
    },

    components: {
        ValueReflectionPDF: () => import('../views/apps/valueReflection/ValueReflectionPDF.vue'),
        ProfilePDF: () => import('../views/apps/profile/ProfilePDF.vue'),
        ProjectReflectionPDF: () => import('../views/apps/reflection/ProjectReflectionPDF.vue'),
        html2pdf,
    },

    computed: {
        projectMetadata() {
            return this.$store.getters['survey/getProjectMeta']
        },
        getAllValuesWithNotes() {
            return this.$store.getters['survey/getAllValuesWithNotes']
        }
    }
}
</script>

<style lang="scss">
#pdf-export-root {
    // Hide in production.
    visibility: hidden;
    height: 0px;
    overflow: hidden;
}

#pdf-export-content * {
    font-family: 'Helvetica Neue', 'Lucida Sans', Helvetica, Arial, 'sans-serif';
    box-sizing: border-box;
}
</style>
