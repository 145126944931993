<template>
    <div>

        <div v-if="tutorialName === 'survey'">
            <div class="icon-and-text">
                <feather-icon class="icon" icon="ListIcon"></feather-icon>
                <p>Im Folgenden können Sie <b>37</b> Fragen der Reihe nach beantworten.</p>
                <feather-icon class="icon" icon="SmartphoneIcon"></feather-icon>
                <p>Der Fragebogen ist primär auf die Entwicklung von <b>Technologien</b> ausgerichtet. Er kann jedoch auch
                    zur Einschätzung anderer angestrebter Forschungsergebnisse verwendet werden.</p>
                <feather-icon class="icon" icon="FastForwardIcon"></feather-icon>
                <p>Beachten Sie bitte, dass sich die Fragen auf das zukünftige <b>Anwendungsszenario</b> der fertig
                    entwickelten Technologie beziehen sollen und nicht auf den Entwicklungsprozess selbst. </p>
                <feather-icon class="icon" icon="UsersIcon"></feather-icon>
                <p>Es wird empfohlen, die Fragen im <b>Team</b> zu diskutieren und zu beantworten.</p>
            </div>
        </div>

        <div v-if="tutorialName === 'profile'">
            <div class="icon-and-text">
                <feather-icon class="icon" icon="AlignLeftIcon"></feather-icon>
                <p> Das ELSI-Profil zeigt, welche <b>ELSI-Kategorien</b> für Ihr Forschungsvorhaben besonders
                    <b>relevant</b>
                    sind. Der Balken, bestehend aus blauen Segmenten, ist ein Relevanzindikator: Je länger der Balken, umso
                    größer ist der Anteil an Fragen, bei denen Ihre Antworten auf<b>wahrscheinliche oder mögliche
                        Auswirkungen</b> hindeuten.
                </p>
                <feather-icon class="icon" icon="PlusSquareIcon"></feather-icon>
                <p>Für jede Frage, bei der Ihre Antwort auf wahrscheinliche oder mögliche Auswirkungen hinweist, wird dem
                    Relevanzindikator ein dunkel- bzw. hellblaues <b>Segment</b> hinzugefügt. Unwahrscheinliche Auswirkungen
                    werden
                    als graue Fläche dargestellt.</p>
                <feather-icon class="icon" icon="DropletIcon"></feather-icon>
                <p>Neben der Balkenlänge ist die <b>Farbe</b> der Segmente ein Anzeiger für die Relevanz. Wir empfehlen,
                    sich zusätzlich mit allen ELSI-Kategorien auseinanderzusetzen, die dunkelblaue Segmente enthalten, also
                    auf wahrscheinliche Auswirkungen hindeuten.</p>
            </div>
        </div>

        <div v-if="tutorialName === 'valueReflection'">
            <div class="icon-and-text">
                <feather-icon class="icon" icon="SearchIcon"></feather-icon>
                <p>Suchen Sie sich die <b>Werte</b> aus, die für Ihr angestrebtes Forschungsergebnis am wichtigsten sind.
                    Ziehen Sie diese in die Zielscheibe (per Drag and Drop). Je weiter in der Mitte ein Begriff platziert
                    wird, desto elementarer sollte er sein.</p>
                <feather-icon class="icon" icon="AlignJustifyIcon"></feather-icon>
                <p>Überprüfen Sie Ihr eigenes <b>Verständnis</b> hinsichtlich der Werte. Lesen Sie sich dazu in Ruhe die
                    Beschreibungen zu den einzelnen Werten durch.</p>
                <feather-icon class="icon" icon="EyeIcon"></feather-icon>
                <p>Versuchen Sie, einen <b>Perspektivwechsel</b> vorzunehmen und sich etwaige unterschiedliche
                    Stakeholderperspektiven zu Ihren Werten vorzustellen. Was verändert sich?</p>
                <feather-icon class="icon" icon="UsersIcon"></feather-icon>
                <p>Es bietet sich an, die Zielscheibe als Ausgangspunkt für eine Diskussion im <b>Team</b> zu nutzen.</p>
            </div>
        </div>

        <div v-if="tutorialName === 'projectReflection'">
            <div class="icon-and-text">
                <feather-icon class="icon" icon="ListIcon"></feather-icon>
                <p>Im Folgenden finden Sie eine <b>Checkliste</b>, die dabei unterstützen soll, einen Transfer von den
                    ELSI-Inhalten zu Ihrem eigenen Forschungsvorhaben herzustellen.</p>
                <feather-icon class="icon" icon="CompassIcon"></feather-icon>
                <p>Möglicherweise passen nicht alle Punkte genau auf Ihre Forschung. Sie sollen lediglich ein
                    <b>Anhaltspunkt</b> sein und zur Reflexion anregen.
                </p>
                <feather-icon class="icon" icon="UsersIcon"></feather-icon>
                <p>Es wird empfohlen, die Checkliste im <b>Team</b> zu bearbeiten, um eine gemeinsame Basis zu schaffen.</p>
            </div>
        </div>
    </div>
</template>

<script>

import FeatherIcon from '../components/FeatherIcon.vue'
export default {
    name: 'tutorial',
    props: {
        tutorialName: String
    },
    components: {
        FeatherIcon
    },
    computed: {
        terms() {
            return this.$store.getters['survey/getTermsByGlossarFilter']
        },
        getShowProfileInOverview() {
            return this.$store.getters['survey/getShowProfileInOverview']
        },
    },
    data() {
        return {
            info: "Auf dieser Seite finden Sie Hintergrundinformationen zur Funktionsweise des Tools.",
        }
    }
}

</script>

<style lang="scss" scoped>
.tutorial {
    display: flex;
}

.icon-and-text {
    display: grid;
    grid-template-columns: 5rem auto;
    grid-column-gap: 1.5rem;

    /deep/ .icon {
        justify-content: right;
        align-self: start;
    }
}
</style>
