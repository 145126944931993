<template>
    <div>
        <b-card class="project-creation-card" :style="{ height: showIntro ? '550px' : '400px' }">

            <div v-if="showIntro" style="margin-bottom: 40px">
                <p>Hervorragend, Sie haben alle Toolbereiche bearbeitet! </p>
                <p>Vergessen Sie nicht, Ihr Projekt als JSON-Datei herunterzuladen. Diese können Sie wieder ins Tool
                    importieren, um zu einem späteren Zeitpunkt weiter an Ihrem Projekt zu arbeiten.</p>
                <p>Ebenso können Sie einzelne Bereiche des Tools in Form einer PDF-Datei herunterladen.</p>
            </div>
            <div class="project-creation">
                <div style="border-right: 4px solid #ECF0F3">
                    <b-button id="json" :pressed.sync="jsonToggle" @click="clickButton" variant="primary"
                        style="margin-bottom: 20px;">
                        <feather-icon icon="LayoutIcon"></feather-icon>
                        JSON
                    </b-button>
                    <b-button id="pdf" :pressed.sync="pdfToggle" @click="clickButton" variant="primary">
                        <feather-icon icon="FileIcon"></feather-icon>
                        PDF
                    </b-button>

                </div>

                <div>
                    <p v-if="!pdfToggle && !jsonToggle">
                        Laden Sie Ihr Projekt entweder als JSON-Datei für eine spätere Weiterbearbeitung oder als PDF für
                        den Druck herunter!
                    </p>
                    <p v-if="jsonToggle">Laden Sie Ihr Projekt als JSON-Datei herunter. Diese Datei können Sie später wieder
                        importieren oder zur weiteren Bearbeitung an andere Personen weiterleiten.</p>
                    <div v-if="pdfToggle">
                        <p>Wählen Sie aus, welche Toolbereiche Sie als PDF-Datei herunterladen möchten.</p>
                        <p>Dateiname</p>
                        <b-form-input v-model="dataName" placeholder="Dateiname"></b-form-input>
                        <b-form-group label="Toolbereiche" v-slot="{ ariaDescribedby }" style="margin-bottom: 40px">
                            <b-form-checkbox-group id="checkbox-group-1" v-model="selected"
                                :aria-describedby="ariaDescribedby" name="flavour-1" style="gap: 10px; padding: 10px">
                                <b-form-checkbox style="margin-bottom: 10px;" value="wertesystem"><span
                                        style="margin-left: 10px">ELSI-Wertereflexion</span></b-form-checkbox>
                                <b-form-checkbox style="margin-bottom: 10px" value="profil"><span
                                        style="margin-left: 10px">ELSI-Profil</span></b-form-checkbox>
                                <b-form-checkbox style="margin-bottom: 10px" value="reflexion"><span
                                        style="margin-left: 10px">Projektreflexion</span></b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </div>
                    <div v-if="jsonToggle">
                        <p>Dateiname</p>
                        <b-form-input v-model="dataName" placeholder="Dateiname"></b-form-input>
                    </div>
                </div>
            </div>
        </b-card>
        <b-button variant="primary" :class="jsonToggle || pdfToggle ? 'download active' : 'download'" @click="downloadOK">
            <feather-icon font-scale="1" icon="DownloadIcon"></feather-icon>
            <span v-if="downloadOption === 'json'">JSON herunterladen</span>
            <span v-if="downloadOption === 'pdf'">PDF herunterladen</span>
            <span v-if="downloadOption === ''">Herunterladen</span>

        </b-button>
        <PDFTemplate :options="this.selected"></PDFTemplate>
    </div>
</template>

<script>
import Button from "@/components/Button.vue";
import ImportJson from "@/components/ImportJSON.vue";
import FeatherIcon from './FeatherIcon.vue';
import html2pdf from 'html2pdf.js';
import PDFTemplate from '../components/pdf-template.vue';

export default {
    name: "ProjectCreationModal",
    props: {
        showImport: String,
        showIntro: Boolean,
    },
    components: {
        Button,
        ImportJson,
        FeatherIcon,
        html2pdf,
        PDFTemplate
    },
    computed: {

    },
    mounted() {

    },
    updated() {

    },
    methods: {
        clickButton(evt) {
            if (evt.target.id === 'json') {
                if (this.jsonToggle) {
                    this.pdfToggle = false
                    this.downloadOption = "json"
                }
                else {
                    this.downloadOption = ''
                }
            }
            else {
                if (this.pdfToggle) {
                    this.jsonToggle = false
                    this.downloadOption = 'pdf'
                }
                else {
                    this.downloadOption = ''
                }
            }
        },
        downloadOK() {
            switch (this.downloadOption) {
                case 'json':
                    this.saveFile()
                    break;
                case 'pdf':
                    this.createPDF()
                    break;
                case '':
                    this.$vs.notify({
                        time: 6000,
                        title: 'Dateiformat fehlt!',
                        text: 'Bitte wählen Sie JSON oder PDF aus.',
                        color: 'danger'
                    })

                    break;
            }
        },
        getOption(option) {
            this.downloadOption = option

        },
        backClicked() {
            this.$router.back()
        },
        createPDF() {
            let opt = {
                margin: [20, 20, 25, 20],
                filename: this.dataName + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                // Scales larger than 2 tend to result in empty pages.
                // https://github.com/eKoopmans/html2pdf.js/issues/19#issuecomment-315583260
                html2canvas: { scale: 1.8 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                pagebreak: { mode: 'avoid-all' }
            };

            const element = document.getElementById('pdf-export-content');

            html2pdf().from(element).set(opt).toPdf().get('pdf').then(function (pdf) {
                let totalPages = pdf.internal.getNumberOfPages();

                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.setTextColor(150);
                    pdf.text(
                        'Seite ' + i + ' von ' + totalPages,
                        pdf.internal.pageSize.getWidth() - 116,
                        pdf.internal.pageSize.getHeight() - 15
                    );
                }
            }).save();

        },
        saveFile: function () {
            const data = JSON.stringify(this.$store.state.survey)
            const blob = new Blob([data], { type: 'text/plain' })
            const e = document.createEvent('MouseEvents'),
                a = document.createElement('a');
            a.download = this.dataName + '.json',
                a.href = window.URL.createObjectURL(blob);
            a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
            e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            a.dispatchEvent(e);
        },
    },

    data() {
        return {
            jsonToggle: false,
            pdfToggle: false,
            downloadOption: '',
            dataName: '',
            selected: [], // Must be an array reference!
            options: [
                { text: 'ELSI-Wertereflexion', value: 'wertesystem' },
                { text: 'ELSI-Profil', value: 'profil' },
                { text: 'Projektreflexion', value: 'reflexion' },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.close {

    background-color: red;
}

button {
    display: flex;
    flex-direction: row;
    padding: 16px 20px 16px 20px;
    gap: 12px;
    width: 200px;
    height: 47px;
    border-radius: 3px;

    font-style: normal;
    font-weight: 400;
    font-size: 15.2px;
    line-height: 100%;
    /* or 15px */
    color: #FFFFFF;
    text-decoration: none;
    border: 0 none;


}

.download {
    background: darken($elsi-color-blue, 8);
    color: $white;
    margin-left: auto;
    justify-content: center;
    width: 240px;

    .active {
        background: darken($elsi-color-blue, 8);
        color: $white;
    }
}

.btn-primary {
    background: $elsi-color-mittelgrau;
    color: $elsi-color-schrift-normal;

    &:hover {
        background: darken($elsi-color-blue, 8);
        color: $white;
    }

    .not-active {
        background: $elsi-color-mittelgrau;
    }

    &.active {
        background: darken($elsi-color-blue, 8);
        color: $white;
    }
}

.project-creation-card {
    border: none;
    padding: 20px;
    width: fit-content;
}

.project-creation {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-gap: 1.5rem;
    margin: 0;
    padding: 0;
}
</style>
