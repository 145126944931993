var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.tutorialName === 'survey')?_c('div',[_c('div',{staticClass:"icon-and-text"},[_c('feather-icon',{staticClass:"icon",attrs:{"icon":"ListIcon"}}),_vm._m(0),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"SmartphoneIcon"}}),_vm._m(1),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"FastForwardIcon"}}),_vm._m(2),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"UsersIcon"}}),_vm._m(3)],1)]):_vm._e(),(_vm.tutorialName === 'profile')?_c('div',[_c('div',{staticClass:"icon-and-text"},[_c('feather-icon',{staticClass:"icon",attrs:{"icon":"AlignLeftIcon"}}),_vm._m(4),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"PlusSquareIcon"}}),_vm._m(5),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"DropletIcon"}}),_vm._m(6)],1)]):_vm._e(),(_vm.tutorialName === 'valueReflection')?_c('div',[_c('div',{staticClass:"icon-and-text"},[_c('feather-icon',{staticClass:"icon",attrs:{"icon":"SearchIcon"}}),_vm._m(7),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"AlignJustifyIcon"}}),_vm._m(8),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"EyeIcon"}}),_vm._m(9),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"UsersIcon"}}),_vm._m(10)],1)]):_vm._e(),(_vm.tutorialName === 'projectReflection')?_c('div',[_c('div',{staticClass:"icon-and-text"},[_c('feather-icon',{staticClass:"icon",attrs:{"icon":"ListIcon"}}),_vm._m(11),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"CompassIcon"}}),_vm._m(12),_c('feather-icon',{staticClass:"icon",attrs:{"icon":"UsersIcon"}}),_vm._m(13)],1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Im Folgenden können Sie "),_c('b',[_vm._v("37")]),_vm._v(" Fragen der Reihe nach beantworten.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Der Fragebogen ist primär auf die Entwicklung von "),_c('b',[_vm._v("Technologien")]),_vm._v(" ausgerichtet. Er kann jedoch auch zur Einschätzung anderer angestrebter Forschungsergebnisse verwendet werden.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Beachten Sie bitte, dass sich die Fragen auf das zukünftige "),_c('b',[_vm._v("Anwendungsszenario")]),_vm._v(" der fertig entwickelten Technologie beziehen sollen und nicht auf den Entwicklungsprozess selbst. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Es wird empfohlen, die Fragen im "),_c('b',[_vm._v("Team")]),_vm._v(" zu diskutieren und zu beantworten.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Das ELSI-Profil zeigt, welche "),_c('b',[_vm._v("ELSI-Kategorien")]),_vm._v(" für Ihr Forschungsvorhaben besonders "),_c('b',[_vm._v("relevant")]),_vm._v(" sind. Der Balken, bestehend aus blauen Segmenten, ist ein Relevanzindikator: Je länger der Balken, umso größer ist der Anteil an Fragen, bei denen Ihre Antworten auf"),_c('b',[_vm._v("wahrscheinliche oder mögliche Auswirkungen")]),_vm._v(" hindeuten. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Für jede Frage, bei der Ihre Antwort auf wahrscheinliche oder mögliche Auswirkungen hinweist, wird dem Relevanzindikator ein dunkel- bzw. hellblaues "),_c('b',[_vm._v("Segment")]),_vm._v(" hinzugefügt. Unwahrscheinliche Auswirkungen werden als graue Fläche dargestellt.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Neben der Balkenlänge ist die "),_c('b',[_vm._v("Farbe")]),_vm._v(" der Segmente ein Anzeiger für die Relevanz. Wir empfehlen, sich zusätzlich mit allen ELSI-Kategorien auseinanderzusetzen, die dunkelblaue Segmente enthalten, also auf wahrscheinliche Auswirkungen hindeuten.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Suchen Sie sich die "),_c('b',[_vm._v("Werte")]),_vm._v(" aus, die für Ihr angestrebtes Forschungsergebnis am wichtigsten sind. Ziehen Sie diese in die Zielscheibe (per Drag and Drop). Je weiter in der Mitte ein Begriff platziert wird, desto elementarer sollte er sein.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Überprüfen Sie Ihr eigenes "),_c('b',[_vm._v("Verständnis")]),_vm._v(" hinsichtlich der Werte. Lesen Sie sich dazu in Ruhe die Beschreibungen zu den einzelnen Werten durch.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Versuchen Sie, einen "),_c('b',[_vm._v("Perspektivwechsel")]),_vm._v(" vorzunehmen und sich etwaige unterschiedliche Stakeholderperspektiven zu Ihren Werten vorzustellen. Was verändert sich?")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Es bietet sich an, die Zielscheibe als Ausgangspunkt für eine Diskussion im "),_c('b',[_vm._v("Team")]),_vm._v(" zu nutzen.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Im Folgenden finden Sie eine "),_c('b',[_vm._v("Checkliste")]),_vm._v(", die dabei unterstützen soll, einen Transfer von den ELSI-Inhalten zu Ihrem eigenen Forschungsvorhaben herzustellen.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Möglicherweise passen nicht alle Punkte genau auf Ihre Forschung. Sie sollen lediglich ein "),_c('b',[_vm._v("Anhaltspunkt")]),_vm._v(" sein und zur Reflexion anregen. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Es wird empfohlen, die Checkliste im "),_c('b',[_vm._v("Team")]),_vm._v(" zu bearbeiten, um eine gemeinsame Basis zu schaffen.")])
}]

export { render, staticRenderFns }