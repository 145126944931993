<template>
    <div class="sidebar" @mouseover="isMouseOver = true" @mouseleave="isMouseOver = false">
        <div class="sidebar-item trenner" style="height: 10rem">
            <feather-icon v-b-modal.modal-back-Start class="icon" icon="ActivityIcon"></feather-icon>
            <span class="mx-3" v-b-modal.modal-back-Start v-if="isMouseOver"
                :class="currentRouteName === '/' ? 'active-nav-text' : 'nav-text'">Startseite</span>
        </div>

        <div :class="currentRouteName === 'uebersicht' ? 'sidebar-item active clickable' : 'sidebar-item clickable'">
            <router-link :to="{ name: 'uebersicht' }">
                <feather-icon :class="currentRouteName === 'uebersicht' ? 'icon icon-active' : 'icon'"
                    icon="UserIcon"></feather-icon>
            </router-link>
            <router-link :to="{ name: 'uebersicht' }"><span class="mx-3" v-if="isMouseOver"
                    :class="currentRouteName === 'uebersicht' ? 'active-nav-text' : 'nav-text'">Projektübersicht
                    <i>{{ projectMetadata.nameProject }}</i> </span>
            </router-link>
        </div>
        <div :class="currentRouteName === 'valueReflection' ? 'sidebar-item active clickable' : 'sidebar-item clickable'">
            <router-link :to="{ name: 'valueReflection' }">
                <feather-icon :class="currentRouteName === 'valueReflection' ? 'icon icon-active' : 'icon'"
                    icon="TargetIcon"></feather-icon>
            </router-link>
            <router-link :to="{ name: 'valueReflection' }"><span class="mx-3" v-if="isMouseOver"
                    :class="currentRouteName === 'valueReflection' ? 'active-nav-text' : 'nav-text'">ELSI-Wertereflexion</span>
            </router-link>
        </div>
        <div
            :class="currentRouteName === 'profile' || currentRouteName === 'survey' ? 'sidebar-item active clickable' : 'sidebar-item clickable'">

            <router-link to="/profile">
                <feather-icon
                    :class="currentRouteName === 'survey' || currentRouteName === 'profile' ? 'icon icon-active' : 'icon'"
                    icon="AlignLeftIcon"> </feather-icon>
            </router-link>
            <router-link to="/profile"> <span class="mx-3" v-if="isMouseOver"
                    :class="currentRouteName === 'survey' || currentRouteName === 'profile' ? 'active-nav-text' : 'nav-text'">ELSI-Profil</span>
            </router-link>

        </div>
        <div :class="currentRouteName === 'elsi_cards' ? 'sidebar-item active clickable' : 'sidebar-item clickable'">

            <router-link :to="{ name: 'elsi_cards' }">
                <feather-icon :class="currentRouteName === 'elsi_cards' ? 'icon icon-active' : 'icon'"
                    icon="GridIcon"></feather-icon>
            </router-link>
            <router-link :to="{ name: 'elsi_cards' }"><span class="mx-3" v-if="isMouseOver"
                    :class="currentRouteName === 'elsi_cards' ? 'active-nav-text' : 'nav-text'">ELSI-Karten</span>
            </router-link>

        </div>
        <div
            :class="currentRouteName === 'reflexion' ? 'sidebar-item active trenner clickable' : 'sidebar-item trenner clickable'">

            <router-link :to="{ name: 'reflexion' }">
                <feather-icon :class="currentRouteName === 'reflexion' ? 'icon icon-active' : 'icon'"
                    icon="CheckSquareIcon"></feather-icon>
            </router-link>
            <router-link :to="{ name: 'reflexion' }"><span class="mx-3" v-if="isMouseOver"
                    :class="currentRouteName === 'reflexion' ? 'active-nav-text' : 'nav-text'">Projektreflexion</span>
            </router-link>

        </div>
        <div :class="currentRouteName === 'knowledge-base' ? 'sidebar-item active clickable' : 'sidebar-item clickable'">

            <router-link to="/knowledge-base"> <feather-icon
                    :class="currentRouteName === 'knowledge-base' ? 'icon icon-active' : 'icon'"
                    icon="BookIcon"></feather-icon></router-link>
            <router-link to="/knowledge-base"> <span class="mx-3" v-if="isMouseOver"
                    :class="currentRouteName === 'knowledge-base' ? 'active-nav-text' : 'nav-text'">Glossar</span>
            </router-link>

        </div>
        <div :class="currentRouteName === 'about' ? 'sidebar-item active clickable' : 'sidebar-item clickable'">

            <router-link :to="{ name: 'about' }">
                <feather-icon :class="currentRouteName === 'about' ? 'icon icon-active' : 'icon'"
                    icon="InfoIcon"></feather-icon>
            </router-link>
            <router-link :to="{ name: 'about' }"> <span class="mx-3" v-if="isMouseOver"
                    :class="currentRouteName === 'about' ? 'active-nav-text' : 'nav-text'">Über das
                    Tool</span>
            </router-link>

        </div>
        <div class="nav-footer">
            <div :class="currentRouteName === 'impressum' ? 'sidebar-item active clickable' : 'sidebar-item clickable'">

                <router-link :to="{ name: 'impressum' }">
                    <feather-icon :class="currentRouteName === 'impressum' ? 'icon icon-active' : 'icon'"
                        icon="BookOpenIcon"></feather-icon>
                </router-link>
                <router-link :to="{ name: 'impressum' }"> <span class="mx-3" v-if="isMouseOver"
                        :class="currentRouteName === 'impressum' ? 'active-nav-text' : 'nav-text'">Impressum</span>
                </router-link>
            </div>
            <div :class="currentRouteName === 'datenschutz' ? 'sidebar-item active clickable' : 'sidebar-item clickable'">
                <router-link :to="{ name: 'datenschutz' }">
                    <feather-icon :class="currentRouteName === 'datenschutz' ? 'icon icon-active' : 'icon'"
                        icon="ShieldIcon"></feather-icon>
                </router-link>
                <router-link :to="{ name: 'datenschutz' }"> <span class="mx-3 word" v-if="isMouseOver"
                        :class="currentRouteName === 'datenschutz' ? 'active-nav-text' : 'nav-text'">Datenschutz</span>
                </router-link>
            </div>
        </div>

        <b-modal id="modal-back-Start" title="Achtung, beim Wechsel zur Startseite wird Ihr Projekt gelöscht!" hide-footer
            size="xl">
            <ModalBackStart />
        </b-modal>
    </div>
</template>

<script>

import Button from '../components/Button.vue'
import FeatherIcon from '../components/FeatherIcon.vue'
import ModalBackStart from '../components/Modal_Project_Back_Start.vue'
import { ActivityIcon } from 'vue-feather-icons'

export default {
    name: 'nav-sidebar',
    props: {
        buttonLabel: String,
        buttonFunction: String,
        link: String,
        icons: Array,
    },
    components: {
        Button,
        ActivityIcon,
        FeatherIcon,
        ModalBackStart
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
        projectMetadata() {
            return this.$store.getters['survey/getProjectMeta']
        },
    },
    methods: {
    },
    data() {
        return {
            wertesystem: "Finden Sie alleine oder im Team heraus, welche Werte  Ihre Forschung bestimmen und Ihrem Forschungsprojekt eine Form geben sollen.",
            profil: "Ermitteln Sie anhand von Fragen, welche Eigenschaften Ihres Forschungsprojektes  bestimmte Implikationen für relevante ELSI-Kategorien mit sich bringen und wie wahr-scheinlich diese sind.",
            karten: "Informieren Sie sich zu relevan-ten ELSI-Kategorien und lassen Sie sich von den Fallbeispielen und dem Reflexionsprozess inspirieren.",
            reflexion: "Reflektieren Sie ihr eigenes Forschungprojekt anhand einer indivialisierbaren ELSI-Checkliste und leisten Sie den Transfer von den ELSI-Inhalten zum eigenen Forschungsvorhaben.",
            isMouseOver: false,
        }
    }
}

</script>

<style lang="scss" scoped>
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 1920px) {
    .sidebar {
        display: flex;
        flex-direction: column;
        position: fixed;
        height: 100%;
        width: 80px;
        background: #FFFFFF;

        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0px 5px 5px 0px;

        .icon {
            scale: 0.8;
        }

        &:hover {
            width: 260px;
            z-index: 1;
            border-right: 2px solid darkgrey;

            .sidebar-item {
                width: 100%;
                justify-content: left;
                display: flex;
                align-self: left;
                align-items: left;
                padding-left: 26px;
            }
        }
    }
}

@media only screen and (min-width: 1921px) {
    .sidebar {
        display: flex;
        flex-direction: column;
        position: fixed;
        gap: 4rem;
        height: 100%;
        width: 80px;
        background: #FFFFFF;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0px 5px 5px 0px;

        &:hover {
            width: 260px;
            z-index: 1;

            .sidebar-item {
                width: 100%;
                justify-content: left;
                display: flex;
                align-self: left;
                align-items: left;
                padding-left: 26px;
            }
        }
    }
}

.sidebar-item {
    width: 100%;
    justify-content: center;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: row;
    border-left: 5px solid #fff0;
}

.active {
    border-left: 5px solid $elsi-color-lightblue;
}

.sidebar-item.trenner {
    border-bottom: 3px solid $elsi-color-backdrop;
}

.icon {
    padding-bottom: 1rem;
    padding-top: 1rem;
    color: $elsi-color-schrift-normal;
}

.icon-active {
    color: $elsi-color-lightblue !important;
}

.active-nav-text {
    color: $elsi-color-lightblue !important;
}

.nav-text {
    color: $elsi-color-schrift-normal;

    &:hover {
        color: $elsi-color-lightblue;
    }
}

.nav-footer {
    position: absolute;
    bottom: 16px;
    width: inherit;
    justify-content: center;
    align-items: center;
}

.word {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
</style>