<template>
    <div>
        <div style="display: flex; align-items: start;">
            <feather-icon icon="DownloadIcon" />
            <span style="margin-left: 20px"> Vergessen Sie nicht, Ihr Projekt als JSON-Datei herunterzuladen. Diese können
                Sie wieder ins Tool importieren, um zu
                einem späteren Zeitpunkt weiter an Ihrem Projekt zu arbeiten.</span>
        </div>

        <div class="custom-modal-footer">
            <Button label="Projekt herunterladen" btnStyle="btn btn-primary" v-b-modal.modal-back-download></Button>
            <Button label="Zur Startseite" btnStyle="btn btn-secondary" chevron="true" linkTo='/'></Button>
        </div>

        <b-modal id="modal-back-download" size="lg" title="Projekt herunterladen" :hide-footer=true>
            <DownloadModal />
        </b-modal>

    </div>
</template>

<script>
import Button from "@/components/Button.vue";
import DownloadModal from "@/components/Modal_Project_Download.vue"
import ImportJson from "@/components/ImportJSON.vue";
import FeatherIcon from './FeatherIcon.vue';
import html2pdf from 'html2pdf.js';
import PDFTemplate from './pdf-template.vue';

export default {
    name: "ProjectCreationModal",
    props: {
        showImport: String,
        showIntro: Boolean,
    },
    components: {
        Button,
        ImportJson,
        FeatherIcon,
        html2pdf,
        PDFTemplate,
        DownloadModal
    },

    computed: {
    },

    mounted() {
    },

    updated() {
    },

    methods: {
    },
}
</script>

<style lang="scss" scoped>
.close {

    background-color: red;
}

button {
    display: flex;
    flex-direction: row;
    padding: 16px 20px 16px 20px;
    gap: 12px;
    width: 200px;
    height: 47px;
    border-radius: 3px;

    font-style: normal;
    font-weight: 400;
    font-size: 15.2px;
    line-height: 100%;
    /* or 15px */
    color: #FFFFFF;
    text-decoration: none;
    border: 0 none;
}

.download {
    background: darken($elsi-color-blue, 8);
    color: $white;
    margin-left: auto;
    justify-content: center;
    width: 240px;

    .active {
        background: darken($elsi-color-blue, 8);
        color: $white;
    }
}

.btn-primaryX {
    background: $elsi-color-mittelgrau;
    color: $elsi-color-schrift-normal;

    &:hover {
        background: darken($elsi-color-blue, 8);
        color: $white;
    }

    .not-active {
        background: $elsi-color-mittelgrau;
    }

    &.active {
        background: darken($elsi-color-blue, 8);
        color: $white;
    }
}

.project-creation-card {
    border: none;
    padding: 20px;
    width: fit-content;
}

.project-creation {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-gap: 1.5rem;
    margin: 0;
    padding: 0;
}

.custom-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 32px;
    padding: 32px;
}
</style>
